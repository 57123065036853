@import url("https://fonts.googleapis.com/css2?family=Racing+Sans+One&display=swap");


.Area{
    background-image: url('../../images/map_out.jpg');
    box-shadow: inset 0 0 0 1000px rgba(255, 255, 255,.2);
    background-repeat: no-repeat;
    background-size: cover;
    height:100vh;
    background-position: right 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    
}
.Map_Area{
    /* background-color: grey; */
    /* border:yellow 1px solid; */
    margin:10px;
    color: black
    
}
.Map_Area >h1{
    font-family: "Racing Sans One";
    font-size: 80px;
    margin-top: -70px;
    margin-bottom: 50px;
    text-align: center;
    /* color: rgb(245, 178, 53) !important; */
  
    
}
.Gallery_Area{
    width:600px ;
    height:450px ;
    border:yellow 2px solid;
    margin: 10px;
}
.Gallery_Area >h1{
    font-family: "Racing Sans One";
    font-size: 80px;
    padding: 20px;
    text-align: center;
    border-bottom: white 1px solid;
    
}

.Map{
    width:600px;
    height:450px ;
    border:black 2px solid;
    
    
}


@media screen and (max-width: 750px) {
  .Map{
      width:100vw;
      height:350px;
  }
  }