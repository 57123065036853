@import url("https://fonts.googleapis.com/css2?family=Racing+Sans+One&display=swap");

.tyre_container {
  background-color: white;
  height: auto;
}
.Collapsible {
  width: 100%;
  text-align: center;
  border: 1px solid grey;
  
  color: white;
  background-color: #353434;
  font: italic;
  font-family: "Racing sans one";
  padding: 8px;
  border-radius: 15px;
  width: 100%;
}

.tyre_container > h1 {
  font-family: "Racing Sans One";
  font-size: 80px;
  text-align: center;
}

.tyre_container > p {
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 18px;
  padding: 20px;
  border-top: black 2px solid;
}
.filter_btn {
  border: 1px solid grey;
  text-align: center;
  color: white;
  background-color: #353434;
  font: italic;
  font-family: "Racing sans one";
  padding: 8px;
  border-radius: 15px;
  width: 100%;
}

.active.filter_btn {
  border: 1px solid grey;
  text-align: center;
  color: white;
  background-color: #353434;
  font: italic;
  font-family: "Racing sans one";
  padding: 8px;
  border-radius: 15px;
  width: 100%;
}

.brand_selection {
  display: flex;
  justify-content: center;
  width: auto;
  border-bottom: black 5px double;
  margin-bottom: 40px;
  overflow: hidden;
}

.tyreGuide {
  width: 355px;
}

.brand_logo {
  background-color: white;
  width: auto;
  height: 100px;
  padding: 5px;
  margin: 5px;
}

.main_display {
  display: flex;
  padding: 10px;
}
.tyre_left {
  width: 400px;
  display: flex;
  flex-direction: column;
  /* border: 2px solid red; */
}
.tyre_display{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
align-items: center;
}
@import url(https://fonts.googleapis.com/css?family=Open+Sans);

body {
  background: #f2f2f2;
  font-family: "Open Sans", sans-serif;
}

.search {
  width: 100%;
  position: relative;
  display: flex;
}

.searchTerm {
  width: 100%;
  border: 2px solid rgb(192, 219, 197);
  border-right: none;
  padding: 5px;
  height: 50px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9dbfaf;
}

.searchTerm:focus {
  color: #141414;
}

.searchButton {
  width: 50px;
  height: 50px;
  border: 1px solid rgb(192, 219, 197);
  background: royalblue;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}

/*Resize the wrap to see the search bar change!*/
.wrap {
  width: 300px;
}
.wrap > p {
  font-size: 14px;
  font-weight: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.tyre_right {
  width: 80%;

  display: flex;
  flex-direction: column;
  /* align-content: center;
    justify-content: center;
    overflow: hidden; */
}

.brand_filter_section {
  background-image: url("../../images/dark.jpg");
  color: white;
  border: black 2px solid;
  height: auto;
  width: auto;
  position: relative;
  right: inherit;
  padding: 10px;
  margin: 10px;
}

.filter {
  font-weight: bolder;
  color: white;
  border-bottom: royalblue 3px solid;
  height: auto;
  text-align: center;
  padding: 10px;
  font-family: "Racing sans one";
  font-style: normal;
  margin-bottom: 20;
}

.list_section {
  margin-bottom: 20px;
  padding: 10px;
  font-size: 20px;
  font-family: "Racing sans one";
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.list_section > li {
  list-style-type: none;
  padding: 5px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.list_section_brands {
  align-self: center;
  width: auto;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 15px;
  font-family: "Racing sans one";
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
}

.list_section_brands > li {
  list-style-type: none;
  padding: 5px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: bold;
  border: 1px solid black;
  border-radius: 5%;
  background-color: rgba(255, 255, 255, 0.1);
}

.list_section_size {
  align-self: center;
  width: 15%;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 20px;
  font-family: "Racing sans one";
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 15px;
}

.list_section_size > li {
  list-style-type: none;
  padding: 5px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  border: 1px solid black;
  border-radius: 8%;
  background-color: rgba(255, 255, 255, 0.1);
}

.white {
  color: white;
}

.black {
  color: black;
}

.green {
  color: green;
}

.blue {
  color: blue;
}

.red {
  color: red;
}

.orange {
  color: orange;
}

.yellow {
  color: yellow;
}

.gold {
  color: gold;
}

.silver {
  color: silver;
}

.grey {
  color: grey;
}

/* for tyreitem */
.cards__item__info_tyre {
  display: inline-flex;
}
.cr_left_tyre {
  width: 75%;
  align-items: center;
  padding: 10px;
}
.cr_left_tyre > p {
  font-size: 15px;
}
.tyre_size {
  font-size: 20px;
}

.cr_right_tyre {
  width: 25%;
  justify-content: center;
  align-items: center;
}
.cr_right_tyre > p {
  font-size: 12px;
}

@media only screen and (max-width: 1524px) {
  
}

@media only screen and (max-width: 1024px) {
  
  .tyre_left{
    width:300px;
  }
  .tyreGuide{
    width:250px;
  }
  .list_section_size{
    font-size: 16px;
  }
  
}

@media only screen and (max-width: 824px) {
  .main_display {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .tyre_left {
    width: 80%;
    margin: auto;
  }
  .tyre_right {
    margin: auto;
  }
  /* .tyre_display {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  } */
}
