@import url("https://fonts.googleapis.com/css2?family=Racing+Sans+One&display=swap");


.app_set{
  padding: 10px;
 display: flex;
 height: 800px;
 justify-content: center;
 width: auto;
}




.S__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.S__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.S__items {
  margin-bottom: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.Service__options {
  background-image: url("../../images/background_s.jpg");
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.9);
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  height: auto;
  background-position: right 0px;
}

.Service__options > h1 {
  font-family: "Racing Sans One";
  font-size: 80px;
  padding: 20px;
  text-align: center;
  border-bottom: white 1px solid;
}

.Service__options > p {
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  /* padding: 20px; */
}

.service-item {
  display: flex;
  flex-direction: row;
}
.service-item2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.service-left {
  margin: 10px;
  display: flex;
  flex-direction: column;
}
.service-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
}
.service-img {
  width: auto;
  height: 150px;
  border-right: 1px solid gainsboro;
  padding: 10px;
}
.service-img2 {
  width: auto;
  height: 250px;
  border-left: 1px solid gainsboro;
  margin: 10px;
}
.s_text {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 18px;
}
@media only screen and (max-width: 1300px) {
  .Service__options {
    height: auto;
  }
}

@media screen and (max-width: 960px) {
  .Service__options {
    height: auto;
  }

  .Service__options > h1 {
    font-family: "Racing Sans One";
    font-size: 60px;
    padding: 20px;
    text-align: center;
  }
  .Service__options > p {
    font-size: 18px;
    /* padding: 20px; */
  }
}

@media screen and (max-width: 768px) {
  .Service__options {
    height: auto;
  }
  .Service__options > h1 {
    font-family: "Racing Sans One";
    font-size: 50px;
    padding: 20px;
    text-align: center;
  }
  .Service__options > p {
    font-size: 18px;
    padding: 20px;
  }
}

@media screen and (max-width: 768px) {
  .s_header {
    font-size: 24px;
  }
  .service-img,
  .service-img2 {
    width: 150px;
    height: auto;
  }
  .s_text {
    font-size: 14px;
  }
  .appointment >embed {
    border: blue 2px solid;
    width: 200px;
    }
}
