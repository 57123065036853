.checkoutProduct {
    display: flex;
    align-content: center;
    /* justify-content: center; */
    border-bottom:silver 1px solid;
    padding-bottom: 10px;
    margin-bottom: 10px;
    margin-top: 40px;
    margin-left: 2%;
  }
  
/* .name_brand{
  display: flex;
  flex-wrap: wrap;
  border: 1px solid red;
} */

.checkoutProduct:hover {
    transform: scale(1.035);
  }
  
  .checkoutProduct__info {
    padding-left: 70px;
  }
  
  .checkoutProduct__info > button {
    position: relative;
    background: rgb(248, 148, 16);
    border: 1px solid;
    margin-top: 10px;
    border-color: #3462a8 #31459c #492984;
    color: white;
    cursor: pointer;
    padding: 5px;
    border-radius:5px;
    font-size: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  .checkoutProduct__image {
    object-fit: contain;
    width: 200px;
    height: 200px;
    margin-top: 10px;
   
  }
  
  .checkout__item__brand {
    color: #464646;
    font-size: 24px;
    line-height: 35px;
    font-weight: bolder;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  display: flex;
  flex-wrap: wrap;
  }

  .checkout__item__name {
    color: #464646;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
     
  }

  .checkout__item__color {
    color: #464646;
    font-size: 20px;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
   font-weight: 600;
   line-height: 20px;
    

  }
 
  .checkout__item__size {
    color: #464646;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  
  }
  .checkout__item__price {
    color: rgb(0, 184, 9);
    font-size: 23px;
    font-variant: normal;
    line-height: 24px;
  }

  @media only screen and (max-width: 530px) {
    .checkoutProduct {
       margin-top: 5px;
   
    }
    .checkoutProduct__info {
      padding-left: 30px;
    }
    .checkoutProduct__info > button {
      margin-top: 4px;
      padding: 5px;
      font-size: 12px;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      align-self: right;
    }
    
     .checkoutProduct__image {
      object-fit: contain;
      width: 140px;
      height: 150px;
      margin-top: 10px;
     
     }

     .checkout__item__brand {
      color: #464646;
      font-size: 20px;
      margin-top: 20px;
      line-height: auto;
      font-weight: bolder;
     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    
    }
  
    .checkout__item__name {
      color: #464646;
      font-size: 14px;
      font-weight: 600;
      line-height: auto;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
       
    }
  
    .checkout__item__color {
      color: #464646;
      font-size: 14px;
     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
     font-weight: 600;
     line-height: auto;
      
  
    }
   
    .checkout__item__size {
      color: #464646;
      font-size: 14px;
      font-weight: 600;
      line-height: auto;
     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    
    }

  }
