.CF_container {
  width: auto;
  margin-top: 40px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  padding: 10px;
}
.t_price {
  text-decoration: none !important;
  color: black;
}
.line1 {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.fname > input,
.lname > input,
.line2 > input {
  border: 1px solid rgb(106, 127, 167);
  border-radius: 6px;
  font-size: 20px;
  color: black;
  padding: 15px;
}
.fname > p,
.lname > p,
.line2 > p {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
}
.fname,
.lname,
.line2 {
  margin-right: 10px;
}

.form > h1 {
  font-size: 20px;
  margin-top: 20px;
}

.Invoice {
  border: 1px solid gainsboro;
  border-radius: 10px;
  background-color: rgba(62, 105, 81, 0.363);
  margin: 10px;
  width: auto;
}
.Invoice > h1 {
  text-decoration: underline 1px grey;
  font-size: 20px;
  padding: 10px;
  margin: 5px;
}
.appointment_setmore {
  height: 650px;
  width: 500px;
  z-index: -1;
  transform: scale(1.2);
  /* overflow: scroll; */
  margin: 10px;
}
.appointment {
  height: 800px;
  margin: 100px;
  z-index: -1;
}

.modal-bg {
  z-index: 100;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  animation-duration: 2s;
  background-image: url("https://media.giphy.com/media/l1J3DaHzWEp2bTpYs/giphy.gif");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s;
}
.bg-active {
  visibility: visible !important;
  opacity: 1;
}
.popup {
  color: white;
  background-color: rgb(71, 71, 71);
  width: auto;
  height: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  /* z-index: 91000; */
  border: 2px solid rgb(0, 132, 255);
  border-radius: 15px;
  padding: 20px;
}
.popup > h2,
.popup > h3 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
  text-align: center;
}

.modal-btn {
  background: rgb(43, 114, 247);
  border: 1px solid;
  margin-top: 10px;
  border-color: #3462a8 #31459c #492984;
  color: white;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  font-size: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.Finish_btn {
  background: rgb(43, 114, 247);
  border: 1px solid;
  margin-top: 10px;
  border-color: #3462a8 #31459c #492984;
  color: white;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  font-size: 30px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
@media only screen and (max-width: 1140px) {
  .popup {
    width: auto;
    height: auto;
  }
  .popup > h2,
  .popup > h3 {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
  }
  .modal-bg {
    background-image: url("https://media.giphy.com/media/l1J3DaHzWEp2bTpYs/giphy.gif");
    background-repeat: repeat;
    background-size: contain;
  }
}
@media only screen and (max-width: 750px) {
  .line1 {
    flex-direction: column;
  }
  .appointment_setmore {
    height: 610px;
    width: 90%;
  }
  .appointment {
    height: auto;
    width: 100%;
    align-items: center;
    padding-right: 5%;
    padding-left: 5%;
  }
}

@media only screen and (max-width: 640px) {
  .google-form {
    width: 98vw;
    margin: 1vw;
  }
  .Invoice {
    margin: 10px;
  }
}

.center {
  top: 50%;
  left: 50%;
}
