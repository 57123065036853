@import url("https://fonts.googleapis.com/css2?family=Racing+Sans+One&display=swap");

.Product__options {
  margin-top: 10px;
  overflow: hidden;
  height: auto;
  width: auto;
}
.Product__options > h1 {
  color: black;
  font-family: "Racing Sans One";
  font-size: 80px;
  padding: 10px;
  text-align: center;
}
.item_display {
  width: 90vw;
  height: auto;
  margin: auto;
  display: flex;
  justify-content: center;
  /* background-color: blue; */
}

.product_cat {
  color: black;
  margin: 20px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  border: 1px solid gainsboro;
  height: 170px;
  width: 170px;
  padding: 10px;
  border-radius: 10px;
}
.product_cat:active {
  -webkit-tap-highlight-color: transparent;
}
.product_cat:hover {
  transform: scale(1.1) !important;
  text-decoration: 2px rgb(0, 140, 255) underline;
}
.product_cat > img {
  object-fit: contain;
  height: 120px;
}

.product_cat > p {
  font-size: 20px;
  font-weight: 600;
  font-family: "Exo", sans-serif;
  text-align: center;
}

.product_cat_ua {
  color: black;
  margin: 20px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: not-allowed;
  border: 1px solid gainsboro;
  height: 170px;
  width: 170px;
  padding: 10px;
  border-radius: 10px;
}
.product_cat_ua:active {
  -webkit-tap-highlight-color: grey;
}
.product_cat_ua:hover {
  cursor: not-allowed;

  /* transform: scale(1.1) !important;
  text-decoration: 2px rgb(0, 140, 255) underline; */
}
.product_cat_ua > img {
  object-fit: contain;
  height: 120px;
}

.product_cat_ua > p {
  font-size: 20px;
  font-weight: 600;
  font-family: "Exo", sans-serif;
  text-align: center;
}
.display {
  position: right;
  overflow: hidden !important;
  height: 20vh;
  margin-top: -100px;
  z-index: -4;
}

.display > img {
  position: relative;
  margin-left: 75%;
  width: 700px;
  margin-top: 0%;
}

@media only screen and (max-width: 1300px) {
  .display {
    margin-top: -8%;
    height: 13vh;
  }
  .display > img {
    position: relative;
    margin-left: 76.5%;
    width: 300px;
    margin-top: 0%;
    overflow: hidden !important;
  }
}

@media screen and (max-width: 960px) {
  .display {
    height: 13vh;
  }
  .display > img {
    position: relative;
    margin-left: 70%;
    width: 300px;
    margin-top: 0%;
    overflow: hidden !important;
  }
  .product_cat {
    margin: 10px;
    height: auto;
    width: auto;
    padding: 10px;
  }
  .product_cat_ua {
    margin: 10px;
    height: auto;
    width: auto;
    padding: 10px;
  }
  .product_cat > img {
    /* object-fit: contain; */
    height: 90px;
  }
  .product_cat_ua > img {
    /* object-fit: contain; */
    height: 90px;
  }

  .product_cat > p {
    font-size: 15px;
    font-weight: 600;
    /* font-family: "Exo", sans-serif;
    text-align: center; */
  }
  .product_cat_ua > p {
    font-size: 15px;
    font-weight: 600;
    /* font-family: "Exo", sans-serif;
    text-align: center; */
  }
}

@media screen and (max-width: 768px) {
  .Product__options {
    height: auto;
  }
  .Product__options > h1 {
    color: black;
    font-family: "Racing Sans One";
    font-size: 50px;
    padding: 5px;
    text-align: center;
  }
  .display {
    position: right;
    overflow: hidden !important;
    height: 20vh;
    z-index: -8;
  }
  .display > img {
    position: relative;
    margin-left: 70%;
    width: 300px;
    margin-top: 0%;
    overflow: hidden !important;
  }
}
@media screen and (max-width: 560px) {
  .Product__options {
    height: auto;
  }
  .item_display {
    width: 100vw;
    height: auto;
    margin: auto;
    display: flex;
    justify-content: center;
  }
  .product_cat {
    margin: 5px;
    margin-bottom: 10px;
    height: auto;
    width: auto;
    padding: 2px;
  }
  .product_cat_ua {
    margin: 5px;
    margin-bottom: 10px;
    height: auto;
    width: auto;
    padding: 2px;
  }
  .product_cat > img {
    width: 100px;
  }
  .product_cat_ua > img {
    width: 100px;
  }

  .product_cat > p {
    font-size: 15px;
    font-weight: 500;
  }
  .product_cat_ua > p {
    font-size: 15px;
    font-weight: 500;
  }

  .display {
    position: right;
    overflow: hidden !important;
    height: 20vh;
    z-index: -8;
  }
  .display > img {
    position: relative;
    margin-left: 70%;
    width: 300px;
    margin-top: 0%;
    overflow: hidden !important;
  }
}
