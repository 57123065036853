@import url("https://fonts.googleapis.com/css2?family=Exo&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Exo:wght@100;400&display=swap");
.Pcolor {
  color: rgb(rgb(8, 243, 8));
}
.header__optionLineOne {
  font-size: 0.9rem;
  font-family: "Exo", sans-serif;
  font-weight: 400;
}
.header__optionLineTwo {
  font-size: 1rem;
  font-weight: 500;
  font-family: "Exo", sans-serif;
}

.header__option {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  margin-right: 10px;
  color: black;
}
.header__option1 > i {
  padding-right: 10px;
}

.header__option2 {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  justify-content: center;
  color: white;
}

.header__optionBasket {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.header__optionBasket > i {
  color: rgb(8, 243, 8);
}
.header__basketCount {
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: underline;
}

.nav_logo:active {
  -webkit-tap-highlight-color: transparent;
}
.nav_logo {
  object-fit: contain;
  width: 115px;
  height: auto;
  margin: 5px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.nav_logo2 {
  object-fit: contain;
  width: auto;
  height: 38px;
  margin: 5px;
}
.nav_logo3 {
  object-fit: contain;
  width: auto;
  height: 50px;
  margin: 5px;

}

.navbar {
  /* background: linear-gradient(90deg, rgb(134, 201, 218) 0%, rgb(5, 47, 94) 100%); */
  /* background: linear-gradient(90deg, rgb(56, 54, 54) 0%, rgb(105, 3, 3) 100%); */
  /* background: linear-gradient(90deg, rgb(46, 74, 110) 0%, rgb(19, 55, 102) 100%); */
  /* background: linear-gradient(90deg, rgb(18, 92, 105) 0%, rgb(235, 230, 227) 100%); */
  background: rgba(10, 10, 14, 0.85);
  height: auto;
  display: flex;
  flex-direction: wrap;
  justify-content: center;
  /* align-items: center; */
  font-size: 1rem;
  /* position: sticky !important; */
  top: -1px;
  z-index: 999;
  width: auto;
  /* overflow-x: hidden; */
  /* border:2px solid yellow; */
}

.navbar-container {
  height: auto;
  width: 100vw;
  margin: -10px;
  padding: 0px;
  /* position: sticky !important; */
  /* border: 2px solid red; */
}
.nav_options {
  top: 0px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 999;
  position: sticky !important;
  border-top: ghostwhite 1px solid;
  /* background: linear-gradient(90deg, rgb(92,92,92) 0%, rgb(56, 56, 56) 100%); */

  /* background: linear-gradient(90deg, rgb(134,201,218) 0%, rgb(5, 47, 94) 100%); */
  /* height: 75px; */

  background: rgba(10, 10, 14, 0.85);
  /* border: 2px solid blue; */
}

.navbar-logo {
  /* color: #fff; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: center;
  margin-bottom: 5px;
}

.nav-item {
  height: 70px;
  list-style: none;
  text-decoration: none;
}
.nav-links > i {
  padding-right: 7px;
  padding-top: 2px;

  /* border: 2px solid red; */
}

.nav-links {
  color: white;
  display: flex;
  text-decoration: none !important;
  padding: 1.5rem 1rem;
  font-family: "Exo", sans-serif;

  font-weight: 500;
}
.nav-link-cart {
  color: white;
  display: flex;
  text-decoration: none;
  padding: 1.5rem 1rem 22px;
  font-family: "Exo", sans-serif;

  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; */
  font-weight: 500;
}
.nav-link-login {
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  text-decoration: none;
  padding: 1rem.5rem 10px;
}

.nav-links:hover {
  border-bottom: 4px solid #0047ab;
  background-color: rgba(255, 255, 255, 0.1);
  text-decoration: none;
  color: #fff;
}
.nav-link-login:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-bottom: 4px solid #0047ab;
  text-decoration: none;
  color: #fff;
}
.nav-link-cart:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-bottom: 4px solid #0047ab;
}
.nav-links:active {
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
}
.nav-link-login:active {
  -webkit-tap-highlight-color: transparent;
}
.nav-link-cart:active {
  -webkit-tap-highlight-color: transparent;
}

.nav-links-mobile {
  display: none;
}

.menu-icon,
.menu-icon1 {
  display: none;
}
.menu-icon1:active {
  -webkit-tap-highlight-color: transparent;
}

@media screen and (max-width: 1300px) {
  .nav-menu.active {
    top: 0px;
    -webkit-tap-highlight-color: transparent;
  }
  .navbar-logo {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    position: relative;
  }
}
@media screen and (max-width: 1260px) {
  .nav_options {
    border-top: none;
  }
  .NavbarItems {
    position: relative;
  }
  .navbar {
    position: sticky !important;
  }
  .navbar-container {
    height: 80px;
    /* border: 2px red solid; */
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;

    left: -100%;
    opacity: 1;
  }

  .nav-menu.active {
    background-color: rgba(0, 0, 0, 0.95);
    top: 0px;
    left: 0;
    opacity: 1;
    /* transition: all 0.5s ease; */
    z-index: -1;
  }

  .menu-icon1 {
    display: flex;
    position: absolute;
    top: 0;
    right: 140px;
    transform: translate(100%, 60%);
    font-size: 2.8rem;
    cursor: pointer;
    text-decoration: none;
  }

  .menu-icon {
    display: flex;
    position: absolute;
    top: 0;
    right: 60px;
    transform: translate(100%, 60%);
    font-size: 2.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: white;
    font-size: 47px;
    margin-top: -1px;
  }
  .fa-bars {
    color: white;
    /* color: #0047ab; */
    margin-right: 35px;
    font-size: 45px;
    margin-top: 0px;
  }
  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid rgb(88, 85, 85);
    /* transition: all 0.3s ease-out; */
  }

  .nav-links-mobile:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transition: 250ms;
  }
  .nav-links {
    display: block;
    text-align: center;
    margin: -5rem auto;
    margin-top: -10px;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 15px;
    border: 1px solid rgb(88, 85, 85);
    /* transition: all 0.3s ease-out; */
  }

  .nav-link-cart {
    display: none;
  }

  .nav-link-login {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -5rem auto;
    margin-top: -10px;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 15px;
    border: 1px solid rgb(88, 85, 85);
    /* transition: all 0.3s ease-out; */
  }
  .header__option {
    display: flex;
    margin-left: auto;
    width: 100%;
  }
  .header__optionBasket {
    display: flex;
    margin-left: auto;
    width: 100%;
  }

  .header__option1 {
    margin-left: auto;
  }
  .header__option2 {
    margin-right: auto;
  }
  .header__optionLineOne {
    font-size: 15px;
    font-family: "Exo", sans-serif;
    font-weight: 200;
  }
  .navbar-logo {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    position: relative;
  }
  .nav_logo {
    object-fit: contain;
    width: 100px;
    height: auto;
  }
  .nav_logo2 {
    object-fit: contain;
    width: 150px;
    height: auto;
  }
  .nav_logo3 {
    object-fit: contain;
    width: 150px;
    height: auto;
  }
}
/* 
 @media screen and (max-width: 1200px) {
  
}  */

@media screen and (max-width: 1020px) {
  .navbar-logo {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    position: relative;
  }

  .navbar-container {
    height: 80px;
  }
  .nav-menu.active {
    top: 0px;
  }
  .nav-links {
    margin-top: -10px;
  }
  .nav-link-login {
    margin-top: -10px;
  }

  .nav_logo {
    object-fit: contain;
    width: 100px;
    height: auto;
  }

  .nav_logo2 {
    object-fit: contain;
    width: 150px;
    height: auto;
  }
  .nav_logo3 {
    object-fit: contain;
    width: 150px;
    height: auto;
  }
  .fa-times {
    font-size: 37px;
    margin-top: 0px;
  }
  .fa-bars {
    font-size: 35px;
    margin-top: 2px;
  }
}

@media screen and (max-width: 750px) {
  .navbar-logo {
    display: flex;
    justify-content: flex-start;
  }
  .nav_logo {
    object-fit: contain;
    width: 100px;
    height: auto;
  }
  .nav_logo2 {
    display: none;
  }
  .nav_logo3 {
    display: none;
  }
  .nav-links {
    margin-top: -20px;
  }
  .nav-link-login {
    margin-top: -20px;
  }
}

@media screen and (max-width: 600px) {
  .navbar {
    width: auto;
    justify-content: flex-start;
  }
  .navbar-container {
    height: 80px;
  }
  .nav-menu.active {
    top: 0px;
  }
  .nav-links {
    margin-top: -20px;
  }
  .nav-link-login {
    margin-top: -20px;
  }

  .nav_options {
    border: hidden;
  }

  .nav_logo {
    object-fit: contain;
    width: 100px;
    height: auto;
  }

  .nav_logo2,
  .nav_logo3 {
    visibility: hidden;
  }
  .fa-times {
    font-size: 37px;
    margin-top: 0px;
  }
  .fa-bars {
    font-size: 35px;
    margin-top: 2px;
  }
}

@media screen and (max-width: 385px) {
  .navbar-container {
    height: 80px;
  }
  .nav-menu.active {
    top: 0px;
  }
  .nav-links {
    margin-top: -25px;
  }
  .nav-link-login {
    margin-top: -25px;
  }

  .nav_logo {
    object-fit: contain;
    width: 100px;
    height: auto;
  }

  .nav_logo2,
  .nav_logo3 {
    visibility: hidden;
  }
  .nav_options {
    border: hidden;
  }
}

.nav-cart {
  top: 10px;
  right: 10px;
  text-decoration: none;
  margin-right: 10px;
}
