.Light_container {
        min-height: 100vh;
        
}
.Light_container > h1{
    font-family: "Racing Sans One";
    font-size: 100px;
    text-align: center;
    border-bottom: rgb(170, 164, 164) 5px double;
}


@media only screen and (max-width: 1024px) {
  
    .Light_container > h1 {
      font-size: 60px;
      padding: 10px;
    }
 
}

  
  @media screen and (max-width: 450px) {

    .Light_container > h1 {
      font-size: 50px;
      /* padding: 10px;     */
    }
  
  }
  