.orders{
  padding: 20px 80px;
  min-height: 100vh;
}
.Product{
  display: flex;
  flex-direction: column;
}
.Product_list{
  border-bottom:1px solid gray;
}
.orders > h1{
  margin: 30px 0;
}
.orderProduct {
    display: flex;
    align-content: center;
    justify-content: center;
    border-bottom:grey 1px solid;
    padding-bottom: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 2%;
  }


  .order__info {
    padding-left: 70px;
    border-right: 1px solid grey;
       
  }
   .name_brand{
       display: flex;
       flex-direction: column;
       font-weight: normal;
      
   }
   .other_info{
       display:flex;
       flex-direction: row;
      
   }

  .order__image {
    object-fit: contain;
    width: 150px;
    margin-top: 10px;
   
  }
  
  .order__item__brand {
    
    color: #464646;
    font-size: 20px;
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin-right: 10px;

  }

  .order__item__name {
    color: #464646;
    font-size: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin-right: 10px;
     
  }

  .order__item__color {
    color: #464646;
    font-size: 20px;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
   margin-right: 10px;
   padding-right: 5px;
   border-right: 1px solid grey;
   
    

  }
 
  .order__item__size {
    color: #464646;
    font-size: 20px;
    margin-right: 10px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  
  }
  .order__item__price {
    color: black;
    font-size: 20px;
    font-variant: normal;
    margin: 10px;
    padding: 10px;
  }

  @media only screen and (max-width: 460px) {
    .orderProduct {
       margin-top: 15px;
   
    }
    .order__info {
      padding-left: 20px;
    }
   
    .order__image {
      object-fit: contain;
      width: 140px;
      margin-top: 10px;
     
     }

     .order__item__brand {
      color: #464646;
      font-size: 20px;
      line-height: 20px;
      font-weight: bolder;
     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    
    }
  
    .order__item__name {
      color: #464646;
      font-size: 14px;
      font-weight: 600;
      line-height: 15px;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
       
    }
  
    .order__item__color {
      color: #464646;
      font-size: 14px;
     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
     font-weight: 600;
     line-height: 20px;
      
  
    }
   
    .order__item__size {
      color: #464646;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      
     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    
    }

  }
