@import url("https://fonts.googleapis.com/css2?family=Big+Shoulders+Stencil+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Big+Shoulders+Stencil+Display&family=Cinzel:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Big+Shoulders+Stencil+Display:wght@900&display=swap");
.d-block {
  object-fit: cover;
  height: 80vh !important;
  width: 80vw !important;
  margin: auto !important;
  filter: grayscale(30%);

  /* -webkit-animation: zoom 30s; Chrome, Safari, Opera */
  /* animation: zoom 30s; */
}
/* **********************************************************Apple Animation ******************************************************* */
@import url("https://fonts.googleapis.com/css?family=Roboto:700");
@keyframes showTopText {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  40%,
  60% {
    transform: translate3d(0, 50%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes showBottomText {
  0% {
    transform: translate3d(0, -100%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
.animated-title {
  color: #fafbfc;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: rgb(3, 2, 2);
  font-style: italic;

  font-family: monospace;
  height: 90vmin;
  margin-left: 20vw;
  position: absolute;
  margin-top: -20vh;
  transform: translate(-50%, -50%);
  width: 90vmin;

  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}
.animated-title > div {
  height: 50%;
  overflow: hidden;
  position: absolute;
  width: 100%;
}
.animated-title > div div {
  font-size: 12vmin;
  padding: 2vmin 0;
  position: absolute;
}
.animated-title > div div span {
  display: block;
}
.animated-title > div.text-top {
  border-bottom: 1vmin solid rgb(255, 138, 42);
  top: 0;
}
.animated-title > div.text-top div {
  animation: showTopText 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  bottom: 0;
  transform: translate(0, 100%);
}
.animated-title > div.text-top div span:first-child {
  color: #1258da;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: rgb(255, 255, 255);
  font-style: italic;
}
.animated-title > div.text-bottom {
  bottom: 0;
}
.animated-title > div.text-bottom div {
  animation: showBottomText 0.5s;
  animation-delay: 1.75s;
  animation-fill-mode: forwards;
  top: 0;
  transform: translate(0, -100%);
}
/* **********************************************************Apple Animation ******************************************************* */

/* **********************************************************Spinning Animation ******************************************************* */

@keyframes tipsy {
  0 {
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
  }
  100% {
    transform: translateX(-50%) translateY(-50%) rotate(360deg);
  }
}

body {
  font-family: helvetica, arial, sans-serif;
  background-color: #2e2e31;
}
.animate__zoomInDown {
  margin-top: 50%;
  margin-left: 90%;
}
.a {
  color: #ffffff;
  /* text-shadow: 0 60px 25px #2e2e31, 0 10px 60px #2e2e31; */
  font-size: 180px;
  font-weight: bold;
  text-decoration: none;
  letter-spacing: -3px;
  margin: 0;
  position: absolute;
  top: 50%;
  /*  left: 250px; */
  transform: translateX(-50%) translateY(-50%);
}
.a > h1 {
  font-size: 100px;
  background: url(https://i.pinimg.com/originals/0f/75/f4/0f75f405ed4d2f84c57e2bfd89b7c904.jpg);
  background-size: contain;
  color: #fff;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(255, 253, 251);
}

.a:before,
.a:after {
  content: "";
  padding: 0.5em 0.2em;
  position: absolute;
  left: 50%;
  width: 100%;
  top: 50%;
  display: block;
  border: 15px solid red;
  transform: translateX(-50%) translateY(-50%) rotate(0deg);
  animation: 10s infinite alternate ease-in-out tipsy;
}

.a:before {
  border-color: #f5f1f1 #f0ecec rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  z-index: -1;
}

.a:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fcf8f8 #f8f3f3;
  box-shadow: 25px 25px 25px rgba(46, 46, 49, 0.8);
}

/* **********************************************************Spinning Animation ******************************************************* */
/* ****************************************** */
.animate__rubberBand {
  font-size: 100px;
  --animate-delay:3.5s ;
  --animate-duration: 3s;
  /* background-image: url("https://img.freepik.com/free-photo/silver-metal-texture-background-design_46250-2268.jpg?size=626&ext=jpg"); */
 background-color: white;
  margin-top: -15%;
   -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-stroke-width: 2px;
  /* -webkit-text-stroke-color: rgb(255, 255, 255); */
  -webkit-text-stroke-color: rgb(5, 66, 46);
}

.third_slide{
   /* top: 200px !important; */
  /* margin-left: auto; */
 
 
}
.third_slide>h1{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.third_slide>a{
text-decoration: none;}
/* ****************************************** */

.hero {
  background-color: black;
  z-index: -1;
  /* margin-bottom: -150px; */
  /* mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)); */
  /* -webkit-mask-image: linear-gradient(to top, transparent .005%, black 5%); */
}

.fitment {
  background: url(https://i.pinimg.com/originals/0f/75/f4/0f75f405ed4d2f84c57e2bfd89b7c904.jpg);
  background-size: contain;
  color: #fff;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-size: 8vw;
  -webkit-transform: rotate(270deg);
  /* border-bottom: 10px dashed rgb(255, 115, 0); */
  margin-right: -65vw !important;
  margin-top: -22vw !important;
  z-index: -2;
}
.slide1 {
  background-image: url(https://img2.goodfon.com/wallpaper/nbig/b/46/siniy-goluboy-tekstury.jpg);
  /* background-image: url('../../images/bronze.jpg');   */
  /* background-size: contain; */
  /* -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;  */
  font-size: 95px;

  color: rgb(255, 147, 24);
  /* -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color:black ; */
  border: 1px solid red;
  width: 800px;
  height: 500px;
  top: 0px;
}
.animate__animated {
  font-weight: 600;
  /* color:  rgb(255, 147, 24); */
}
.animate__wobble {
  background-image: url(https://i1.pickpik.com/photos/895/1018/421/textures-wood-preview.jpg);
  background-size: contain;
  font-family: "Big Shoulders Stencil Display", cursive;

  font-size: 110px;
  --animate-duration: 4s;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: rgb(255, 147, 24);
}
:root {
  --animate-delay: 4s;
}
.slide1_text {
  margin-top: 10px;
  right: -60%;
  position: relative;
  display: flex;
  /* border: 2px yellow solid; */
  width: 400px;
  border: 10px solid red;
}
.slide2_text {
  margin-top: -90px;
  right: -60%;
  position: relative;
  display: flex;
  /* border: 2px yellow solid; */
  width: 400px;
  z-index: -1;
}

@media screen and (max-width: 1240px) {
  .slide2_text {
    margin-top: -90px;
    right: -50%;
    position: relative;
    display: flex;
    /* border: 2px yellow solid; */
    width: 400px;
  }
  .slide2 {
    font-size: 90px;
    font-weight: 600;
  }
}
@media screen and (max-width: 1024px) {
  .d-block {
    object-fit: contain;
    /* height: auto !important; */
    width: 100vw !important;
    margin: auto !important;
  }
  .fitment {
    font-size: 15vw;
    margin-right: -75vw !important;
    margin-top: -38vw !important;
  }
  .slide1 {
    -webkit-text-stroke-width: 1px;

    font-size: 65px;
  }
  .slide1_text {
    margin-top: -200px !important;
    right: -60%;
    position: relative;
    display: flex;
    width: 260px;
  }
  .slide2_text {
    margin-top: -90px;
    right: -50%;
    position: relative;
    display: flex;
    /* border: 2px yellow solid; */
    width: 400px;
  }
  .animate__wobble {
    font-size: 90px;
  }
  .animated-title {
    height: 90vmin;
    margin-left: 25vw;
    margin-top: -30vh;
    width: 90vmin;
  }
  .animate__zoomInDown {
    margin-top: 80%;
    margin-left: 80%;
  }
}
@media screen and (max-width: 880px) {
  .slide2_text {
    margin-top: -90px;
    right: -40%;
  }
  .animated-title {
    height: 90vmin;
    margin-left: 35vw;
    margin-top: -20vh;
    width: 90vmin;
  }
}
@media screen and (max-width: 760px) {
  .slide2_text {
    margin-top: -90px;
    right: -35%;
    position: relative;
    display: flex;
    /* border: 2px yellow solid; */
    width: 300px;
  }
  .slide2 {
    font-size: 65px;
    font-weight: 600;
    -webkit-text-stroke-width: 1px;
  }
  .animated-title {
    height: 90vmin;
    margin-left: 35vw;
    margin-top: -20vh;
    width: 90vmin;
  }
  .animate__zoomInDown {
    margin-top: 80%;
    margin-left: 70%;
  }
  .animate__rubberBand {
    font-size: 80px;
    margin-top: -60%;
    
  }
}

@media screen and (max-width: 660px) {
  .d-block {
    object-fit: contain;
    height: 70vh !important;
    width: 100vw !important;
    margin: auto !important;
  }
  .slide1 {
    font-size: 40px;
    text-align: center;
    -webkit-text-stroke-width: 1px;
  }
  .slide1_text {
    top: 10px;
    right: -54%;
    width: 165px;
    /* border: 2px solid tomato; */
  }
  .slide2_text {
    margin-top: -90px;
    right: -20%;
    position: relative;
    display: flex;
    /* border: 2px yellow solid; */
    width: 200px;
  }
  .slide2 {
    font-size: 55px;
    font-weight: 600;
  }
  .animate__zoomInDown {
    margin-top: 100%;
    margin-left: 50%;
  }
  .animate__wobble {
    font-size: 70px;
  }
  .animate__rubberBand {
    font-size: 60px;
    margin-top: -60%;
    
  }
}
@media screen and (max-width: 524px) {
  .slide2_text {
    margin-top: -90px;
    right: -10%;
    position: relative;
    display: flex;
    /* border: 2px yellow solid; */
    width: 200px;
  }
  .animate__wobble {
    font-size: 50px;
  }
  .slide2 {
    font-size: 45px;
    font-weight: 600;
  }
  .animated-title {
    height: 90vmin;
    margin-left: 35vw;
    margin-top: -10vh;
    width: 90vmin;
  }
  .a > h1 {
    font-size: 80px;
  }
  .animate__zoomInDown {
    margin-top: 80%;
    margin-left: 50%;
  }
  .animate__rubberBand {
    font-size: 50px;
    margin-top: -60%;
    
  }
}

@media screen and (max-width: 376px) {
  /* .d-block {
    object-fit: contain;
    height:0vh !important;
    width: 100vw !important;
    margin: auto !important;
    
  } */
  .animated-title {
    height: 90vmin;
    margin-left: 35vw;
    margin-top: -20vh;
    width: 90vmin;
  }
  .slide1 {
    font-size: 40px;
    text-align: center;
    -webkit-text-stroke-width: 1px;
  }
  .slide1_text {
    top: 10px;
    right: -54%;
    width: 165px;
    /* border: 2px solid tomato; */
  }
  .slide2_text {
    margin-top: -90px;
    right: -10%;
    position: relative;
    display: flex;
    /* border: 2px yellow solid; */
    width: 200px;
  }
  .animate__wobble {
    font-size: 50px;
  }
  .slide2 {
    font-size: 40px;
    font-weight: 600;
  }
  .animate__rubberBand {
    font-size: 40px;
    margin-top: -80%;
    
  }
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(1, 1);
  }
  to {
    -webkit-transform: scale(1.5, 1.5);
  }
}

/* Standard syntax */
@keyframes zoom {
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(1.5, 1.5);
  }
}
