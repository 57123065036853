.checkout {
    
    padding: 20px;
    background-color: white;
    min-height: 100vh;
  }
  
  .checkout__title {
    margin-right: 10px;
    padding: 10px;
    border-bottom: 1px solid lightgray;
    
  }
  .ad_images{
    width: 100%;
    display: flex;

  }
  .checkout__ad {
    align-self: center;
    padding: 20px;
    width: 40%;
    height: 20%;
    margin-bottom: 10px;
  }


  @media only screen and (max-width: 1024px) {
    .checkout{
      display:flex;
      flex-direction: column;

     }
    .checkout__right{
      width: 100%;
      margin-left: auto;
      position: sticky;
    }
  }