@import url("https://fonts.googleapis.com/css2?family=Racing+Sans+One&display=swap");


.Product_render{
    background-color: lightgray;
    text-align: center;
    
}

.Product_render > h1 {
    font-family: "Racing sans one";
    padding: 20px;
    font-style: none;
    font-size: 50px;

}

.Upload-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin: 10px;
    
}

.Upload-form > input{
    padding: 10px;
    margin: 10px;
    box-shadow: 3px 8px 10px #888888;
    
}

.Upload-form > button{
    padding:10px;
    margin: 10px;
    color:grey ;
    box-shadow: 3px 8px 10px #888888;
}

.list{
    margin-bottom: 24px;
    width: 300px;
  display: inline-flex;
  /* flex-wrap: wrap; */
  flex-direction: row;
  align-items: center;
 
  margin: 5px;
  justify-content: center;
}