

.P__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.P__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.P__items {
  margin-bottom: 24px;
  width: 100%;
}

.P__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
  /* background-color: rgba(253, 135, 25, 0.783); */
  background-color: rgba(0,0,0,0.5);
  
  width: 400px;
}

.P__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.7);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.P__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
  
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.P__item__img {
  position:absolute;
  padding: 10px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  width: auto;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: contain;
  transition: all 0.2s linear;
}

.P__item:hover {
  transform: scale(1.05) !important;
}

.P__item__info {
  text-align: center;
}

.P__item__text {
  font-style: italic;
  padding: 20px;
  color: white;
  font-size: 28px;
  line-height: 24px;
  border-top: 2px solid whitesmoke;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

  @media only screen and (min-width: 1024px) {
  .P__items {
    display: flex;
    
  }

}

@media only screen and (max-width: 1300px) {
   .P__item{
    width: 250px;
    display: flex;
    margin-bottom: 2rem; 
  }
 
}
@media screen and (max-width: 960px) {
  .P__item{
    width: 250px;
    display: flex;
    margin-bottom: 2rem;
    
  }
}
@media screen and (max-width: 760px) {
  .P__item{
    width: 200px;
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
    
  }
}
