@import url("https://fonts.googleapis.com/css2?family=Racing+Sans+One&display=swap");
.Collapsible {
  width: 100%;
  text-align: center;
  border: 1px solid grey;
  color: white;
  background-color: #353434;
  font: italic;
  font-family: "Racing sans one";
  padding: 8px;
  border-radius: 15px;
  width: 100%;
}

.rim_container {
  background-color: white;
  height: auto;
}
.list {
  width: 260px !important;
  margin: 10px !important;
}
.brand__selection > h1 {
  font-family: "Racing Sans One";
  font-size: 80px;
  text-align: center;
  margin-left: 40px;
  margin-right: 40px;
  /* border-bottom: rgb(170, 164, 164) 5px double; */
}

.display_items {
  color: black;
  font-weight: 600;

  border-bottom: rgb(43, 114, 247) 2px solid;
  padding: 5px;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 25px;
  margin: 10px;
}

.rim_container > p {
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 25px;
  padding: 20px;
  border-top: black 2px solid;
}
.brand__selection {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: auto;
  height: 50px; */
  border-bottom: rgb(170, 164, 164) 2px solid;
  margin-bottom: 10px;
  overflow: hidden;
  object-fit: contain;
}

.brand_logo {
  /* background-color: black; */
  width: auto;
  height: 40px !important;
  padding: 5px;
  margin: 5px;
  /* object-fit: contain; */
}
.brand_logo-nexen {
  /* background-color: black; */
  width: auto;
  height: 90px !important;
  padding: 5px;
  margin: 5px;
  /* object-fit: contain; */
}

.main_display {
  display: flex;
  padding: 5px;
  /* border: tomato 2px solid; */
}
.rim_left {
  width: 320px;
  display: flex;
  margin: 0px;
  padding: 0px;
  margin-bottom: 20px;
  /* border: green 2px solid; */
  flex-direction: column;
  align-items: center;
}

.rim_display {
  width: auto;
  padding: 10px;
}

.ShowALLButton {
  color: white;
  align-self: center;
  padding: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15%;
  border: royalblue 3px solid;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.47);
  cursor: pointer;
}
.ShowALLButton_sm {
  color: white;
  align-self: center;
  padding: 5px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 15px;
  border: royalblue 3px solid;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.47);
  cursor: pointer;
  margin: 5px;
}

.tab_filter {
  display: none;
  /* z-index: -3; */
}

.rim_right {
  width: 80%;
  /* border: violet 3px solid; */
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  /* align-content: center; */
  /* justify-content: center;
    overflow: hidden; */
}

.brand_filter_section {
  background-image: url("../../images/dark.jpg");
  color: white;
  border: black 2px solid;
  border-radius: 1%;
  height: auto;
  width: auto;
  /* position: absolute; */
  right: inherit;
  margin-left: 0px;
  padding: 0px;
  margin: 0px;
}

.filter {
  font-weight: 100 !important;
  color: white;
  border-bottom: royalblue 3px solid;
  height: auto;
  text-align: center;
  margin: 10px;
  font-family: "Racing sans one";
  /* font-style: none; */
  margin-bottom: 20;
}

.list_section {
  margin-bottom: 20px;
  padding: 10px;
  font-size: 20px;
  font-family: "Racing sans one";
  display: grid;
  grid-template-columns: repeat(2, 1fr) !important;
}

.list_section > li {
  list-style-type: none;
  font-size: 16px;
  padding: 5px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.list_section > li:hover {
  cursor: pointer;
  transform: scale(1.25);
}

.list_section_brands {
  align-self: center;
  text-align: center;
  width: auto;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 15px;
  font-family: "Racing sans one";
  display: grid;
  grid-template-columns: repeat(6, 1fr) !important;
  grid-gap: 5px;
}

.list_section_brands > li {
  list-style-type: none;
  padding: 5px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: bold;
  border: 1px solid black;
  border-radius: 5%;
  background-color: rgba(255, 255, 255, 0.1);
}
.list_section_brands > li:hover {
  cursor: pointer;
  transform: scale(1.15);
}
.list_section_size {
  align-self: center;
  width: auto;
  margin-bottom: 10px;
  padding: 5px;
  font-size: 20px;
  font-family: "Racing sans one";
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 15px;
}

.list_section_size > li {
  list-style-type: none;
  padding: 5px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  border: 1px solid black;
  border-radius: 8%;
  background-color: rgba(255, 255, 255, 0.1);
}
.list_section_size > li:hover {
  cursor: pointer;
  transform: scale(1.25);
}

.list_section_size > li {
  list-style-type: none;
  padding: 5px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  border: 1px solid black;
  border-radius: 8%;
  background-color: rgba(255, 255, 255, 0.1);
}

.white {
  color: white;
}

.black {
  color: black;
}

.green {
  color: green;
}

.blue {
  color: rgb(43, 114, 247);
}

.red {
  color: rgb(247, 36, 36);
}

.orange {
  color: darkOrange;
}

.yellow {
  color: yellow;
}

.gold {
  color: gold;
}

.silver {
  color: silver;
}

.grey {
  color: grey;
}

.bronze {
  color: #654321;
}
.pink {
  color: pink;
}
.purple {
  color: purple;
}
.chrome {
  color: silver;
}

/* @media only screen and (max-width: 1824px) {
  .Rim_display {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }
}
@media only screen and (max-width: 1324px) {
  .Rim_display {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
} */

@media only screen and (max-width: 1024px) {
  .rim_display {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .list {
    margin-left: auto;
  }
  .rim_container > h1 {
    font-size: 60px;
    padding: 10px;
  }

  .brand_logo {
    height: 40px !important;
  }

}

@media only screen and (max-width: 824px) {
  .main_display {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .rim_left {
    margin: auto;
    width: 80%;
  }
  .rim_right {
    margin: 0px;
  }
  .rim_display {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0px;
    /* border: 2px solid red; */
  }
  .tab_filter {
    /* display: unset; */
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
/* border:2px solid blue; */
    height: auto;
    width: 100%;
  }
  .brand_filter_section {
    display: none;
  }
}

@media screen and (max-width: 650px) {
  .rim_display {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 450px) {
  .brand__selection {
    margin-bottom: 10px;
  }
  .rim_display {
    margin: 0;
    width: 100%;
    /* background-color: blue; */
  }
  .list {
    padding: 0;
    margin: -10px;
    width: auto;
  }

  .rim_container > h1 {
    font-size: 50px;
    /* padding: 10px;     */
  }

  .brand_filter_section {
    background-image: url("../../images/tyre-size-guide.png");
    color: white;
    border: black 2px solid;
    border-radius: 1%;
    height: auto;
    width: 100vw;
  }
  .rim_left {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* margin-top: 10px; */
    margin-bottom: 10px;
  }
  .rim_right {
    width: 100%;
  }

  .tab_filter {
    visibility: visible;
    width: 100%;
  }
  .brand_filter_section {
    /* visibility: collapse; */
    display: none;
  }
}
