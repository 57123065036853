.subtotal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 400px;
  height: 200px;
  padding: 20px;
  background-color: #f3f3f3;
  border: 1px solid #dddddd;
  border-radius: 5px;
  position: relative;
  top: 1%;
}
.price {
  color: rgb(0, 184, 9);
  font-size: 30px !important;
}
.subtotal__gift {
  display: flex;
  align-items: center;
}

.subtotal__gift > input {
  margin-right: 5px;
}

.subtotal > button {
  background: rgb(43, 114, 247);
  border: 1px solid;
  margin-top: 10px;
  border-color: #3462a8 #31459c #492984;
  color: white;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  font-size: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

@media only screen and (max-width: 1024px) {
  .subtotal {
    width: auto;
    margin-top: 15px;
    position: relative;
  }
}
