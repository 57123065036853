.OA_container {
  min-height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;
}

.OA_container_h1{
  margin: 20px;
  /* border-bottom:1px grey solid; */
}

.order_img{
  width:120px;
  margin: 10px;
}

/* .Orders_component{
  
} */

.Order_item{
  border: 1px solid rgb(14, 76, 245);
  margin: 10px;
}

.order_info{
padding-left: 5px;
}
.order_info>p{
  font-size: 20px;
}
.Product_list{
  padding: 5px;
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
 
}

.Product_item{
  /* background-color: cadetblue; */
  background-color: gainsboro;
  margin: 10px;
  
  padding-left:5px;
  border: 1px grey solid;
  display: flex;
}

.order_list_right{
  margin-left: 5px;
  margin-right: 5px;
}

.appointment_calendar{
  border-width:0;
   width:800px;
    height:600px; 

}
.OA_right, .OA_left {
  width: auto;
  margin: 20px;
 } 
@media only screen and (max-width: 1200px) {
  .appointment_calendar{
    border-width:0;
     width:90%;
      height:600px; 
  
  }
  .OA_right {
   width: 100%;
    /* border: black 1px solid; */
  }
  .OA_container {
    min-height: 100vh;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
  }
}