@import url("https://fonts.googleapis.com/css2?family=Racing+Sans+One&display=swap");


*, ::before, ::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    background: #fff;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    /* position: relative; */
    width: auto;
    height: auto;
    background-image: url('../../images/dark.jpg');
    word-break: break-all;
    /* border: 1px solid rgba(0, 0, 0, 0.274); */
    border-radius: 5%;
  }
  
  .bloc-tabs {
    display: flex;
    
  }
  .tabs {
    font-family: "Racing Sans One";
    font-size:20px;
    padding: 10px;
    text-align: center;
    width: 100%;
    background: rgba(128, 128, 128, 0);
    cursor: pointer;
    border-bottom: 1px solid rgb(255, 255, 255);
    box-sizing: content-box;
    position: relative;
    outline: none;
    color: white;
    
  }
  .tabs:not(:last-child){
    border-right: 1px solid rgba(0, 0, 0, 0.274);
  }
  
  .active-tabs  {
    /* background-image: url('../../images/dark.jpg'); */
    border-bottom: 1px solid transparent;
    /* border-left: white 1px solid;
    border-right: white 1px solid; */
    color: white;
        
  }

  .tabs:active{
  -webkit-tap-highlight-color: transparent;
    
  }
  
  .active-tabs::before {
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 2px);
    height: 5px;
    background: rgb(88, 147, 241);
  
    
  }
  
  
  
  button {
    border: none;
    
  }
  .content-tabs {
    flex-grow : 1;
  }
  .content {

    padding: 5px;
    width: 100%;
    height: 100%;
    display: none;
    border-bottom-left-radius: 5%;
    border-bottom-right-radius: 5%;
}
  .content h2 {
    padding: 10px 0px 0px 10px;
    color: white;
    font-size: 20px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }
  .content hr {
    width: 100px;
    height: 2px;
    background: #222;
    margin-bottom: 5px;
  }
  .content p {
    width: 100%;
    height: 100%;
  }
  .active-content {
    display: block;
  }

.list_section {
   margin: 0px;
   padding: 0px;
}
.list_section > li {
    font-size: 15px;
    color: white;
}
.list_section_brands_t {
  align-self: center;
  width: auto;
  margin-bottom: 20px;
  padding: 0px;
  font-size: 13px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;
}


.list_section_brands_t > li {
  color: white;
  list-style-type: none;
  padding: 5px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  border: 1px solid black;
  border-radius: 5%;
  background-color: rgba(255, 255, 255, 0.1);
}
.list_section_brands >  li:hover {
  cursor: pointer;
  transform: scale(1.15);
}
.tab_size > li {
    list-style-type: none;
    padding: 5px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: 500;
    border: 1px solid black;
    border-radius: 8%;
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    
}
.tab_size > li:hover {
    transform: scale(1.15);
}
.tab_size {
    align-self: center;
    width: auto;
    margin-bottom: 20px;
    padding: 10px;
    font-size: 15px;
    font-family: "Racing sans one";
    display: grid;
   
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 5px;
    
}