@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

.add_to_cart {
  color: rgb(43, 114, 247);
  cursor: pointer;

}
.add_to_cart:active {
  -webkit-tap-highlight-color: transparent;
}
.add_to_cart:hover {
  transform: scale(1.15);
}

.bluee {
  color: rgb(43, 114, 247);
}

.cards__item {
  display: flex;
  border-radius: 10px;
  background-color: white;
  width: auto;
  height: auto;
  border: 2px solid gainsboro;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 260px;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 5px;
  overflow: hidden;
  height: 250px;
  /* border: blue 2px solid; */
}

.cards__item__img {
  position: absolute;
  display: block;
  width: 250px;
  height: auto;
  object-fit: contain;
  transition: all 0.2s linear;
}

.cards__item:hover {
  cursor: pointer !important;
  transform: scale(1.05) !important;
}

.cards__item__info {
  display: block;
  height: auto;
  padding-left: 10px;
}

.cards__item__brand {
  color: #464646;
  font-size: 20px;
   margin-left: 10px;
  font-weight: bolder;
 font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
 border-bottom: gainsboro 1px solid;

}
.cards__item__name {
  margin: 0px;
  margin-left: 10px;
  color: #464646;
  font-size: 16px;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.cards__item__size {
  margin: 0px !important;

  color: #464646;
  font-size: 16px;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  
}
.cards__item__set{
  margin: 0px !important;
  color: #464646;
  font-size: 14px;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.cards__item__color {
  margin: 0px !important;
  color: #464646;
  font-size: 16px;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.cards__item__price {
  margin: 5px !important;

  color: rgb(0, 184, 9);
  font-size: 24px;
  font-variant: normal;

 font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}
.price_info{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2px;
  
}
.p1{
  font-size: 14px;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  margin: 0px !important;
  margin-right: 10px !important;

}
/* .cards__item__stock{
  color: rgb(0, 184, 9);

} */
.cr_left {
  width: 70%;
}

.cr_right {
  width: auto;
  padding: 5px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cr_right > img {
  align-self: center;
}
.cr_right > center>button {
  background-color: transparent;
}

.cr_right > p {
  text-align: center;
  color: #464646;
  padding: 10px;
  font-size: 12px;
}

@media screen and (max-width: 710px) {
  .cards__item__link {
    width: 300px;
  }
  .cards__item {
    width: auto !important;
    
  }
  .cards__item__pic-wrap {
    position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 5px;
  overflow: hidden;
  height: 250px;
  }
} 
@media screen and (max-width: 378px) {
  .add_to_cart {
   
    font-size: 40px !important;
  }
  .cards__item__pic-wrap {
    position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 5px;
  overflow: hidden;
  height: 250px;
  }
  .cards__item {
    display: flex;
    /* flex: 1; */
    border-radius: 10px;
    background-color: white;
    width: auto !important;
    height: auto;
    /* border: 2px black solid; */
  }
  .cards__item__img {
    position: absolute;
    /* top: 0;
    right: 0;
    bottom: 0;
    left: 0; */
    display: block;
    width: 250px;
    height: auto;
    object-fit: contain;
    transition: all 0.2s linear;
  }

   .cards__item__info {
    height: 150px;
  }
  .cards__item__brand {
   
    font-size: 17px;
  }
  .cards__item__name {
    font-size: 14px;
    /* line-height: 20px; */
  }
  .cards__item__size {
    font-size: 14px;
    /* line-height: 20px; */
  }

  .cards__item__color {
    font-size: 14px;
    /* line-height: 20px; */
  }
  .cards__item__price {
    font-size: 20px;
    /* line-height: 45px; */
  }
  .cards__item__link {
    width: 300px;
  }
}


.Toastify__toast--success {
  background-color: #5cb85c !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  font-size: 20px;
}
.Toastify__toast--warning {
  
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  font-size: 20px;
  color: black;
}
