.login{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

.login__logo{
    margin-top: 20px;
    margin-bottom: 20px;
    object-fit: contain;
    width: 100px;
    margin-right: auto;
    margin-left: auto;
}

.login__container{
    width: 300px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border: 1px solid lightgray;
    padding: 20px;
    border-radius: 5%;
    align-items: center;

}
.login__container > h1{
    font-weight: 500;
    margin-bottom: 20px;

}

.login__container > form > h5{
    margin-bottom: 5px;
}

.login__container > form > input{
    height: 30px;
    margin-bottom: 10px;
    background-color: white;
    width: 98%;
}

.login__container > p{
    margin-top: 15px;
    font-size: 12px;
    
}

.login__signInBtn{
   color: azure;
    border-radius: 2px;
    width: 100%;
    height: 30px;
    border: 1px solid;
    margin-top: 10px;
    background: #4285f4;
    border-color: #3462a8 #31459c #492984;


}

.login__registerButton{
    border-radius: 2px;
    width: 100%;
    height: 30px;
    border: 1px solid;
    margin-top: 10px;
    border-color: darkgray;
}

.google-btn {
    margin: 10px;
    cursor: pointer;
    width: 100%;
    height: 42px;
    background-color: #4285f4;
    border-radius: 2px;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);}
    .google-icon-wrapper {
      position: absolute;
      margin-top: 1px;
      margin-left: 1px;
      width: 40px;
      height: 40px;
      border-radius: 2px;
      background-color: #fff;
    }
    .google-icon {
      position: absolute;
   
      width: auto;
      height: 40px;
    }
    .btn-text {
      text-align: center;
      margin: 11px 11px 0 0;
      color: #fff;
      font-size: 14px;
      letter-spacing: 0.2px;
      font-family: "Roboto";
    }
  
  
  @import url(https://fonts.googleapis.com/css?family=Roboto:500);
.login_button_fb{
    border-radius: 2px;
    width: 100%;
    height: 30px;
    border: 1px solid;
    margin-top: 10px;
    border-color: darkgray;
    color: white;
    background: rgb(43, 114, 247);
    display:inline-flex;
    justify-content: center;
    align-content: center;
}
.login_button_fb >p{
   
    display:flex;
    justify-content: center; 
  /*   align-content: center;*/
}