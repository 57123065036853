.footer-container{
    
   /* background: linear-gradient(90deg, rgb(90, 91, 94) 0%, rgb(6, 17, 70) 100%); */
   
/* background: linear-gradient(90deg, rgb(92,92,92) 0%, rgb(56, 56, 56) 100%); */
  /* background: linear-gradient(90deg, rgb(134, 201, 218) 0%, rgb(5, 47, 94) 100%); */
    
    /* 
    
    /* padding: 4rem 0 2rem 0; */
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    height: auto;
    /* top:-10px; */
    margin-bottom: -20px !important;
    padding-bottom: 0px;
    background-color: rgba(10, 10, 14, .85);
}

.footer-link-items{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:10px;
    text-align: center;
    width: auto;
    
}

.footer-link-items h2{
    margin-bottom: 16px; 
    color: #fff;
}


.footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 8px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: normal;
    font-size: 15px;
}

.footer-link-items a:hover{
    color:#e9e9e9;
    transition: 0.3s ease-out;

}

.social-icon-link{
    color: #fff;
    font-size: 24px;

}

.website-rights{
    color: #fff;
    margin-bottom: 16px;
    justify-content: flex-end;
}
.footer-logo{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 20px;
}
.footer-logo>img{
    width:200px;
    margin: 5px;
}

@media screen and (max-width: 820px){
 .footer-logo>img{
     width:150px;
 }
}